import axios from 'axios';

export const createLeadInMonday = async (name, phone, wishes, email) => {
    let emailStr = '';
    let wishesStr = '';
    let phoneStr = '';

    // Получение текущей даты в формате YYYY-MM-DD
    const currentDate = new Date().toISOString().split('T')[0];

    if (email) {
        emailStr = `, \\"_________2\\": {\\"email\\": \\"${email}\\", \\"text\\": \\"${email}\\"}`;
    }

    if (wishes) {
        wishesStr = `, \\"_____79\\": \\"${wishes}\\"`;
    }

    if (phone) {
        phoneStr = `, \\"phone\\": {\\"phone\\": \\"${phone}\\", \\"countryShortName\\": \\"RU\\"}`;
    }

    const query = `
      mutation {
        create_item(
          board_id: 2564050939, 
          group_id: "topics", 
          item_name: "Заявка", 
          column_values: "{\\"name\\": \\"Заявка\\", \\"name4\\": \\"${name}\\", \\"date4\\": \\"${currentDate}\\"${phoneStr}${emailStr}${wishesStr}}"
        ) {
          id
          name
        }
      }
    `;


    const url = 'https://api.monday.com/v2';
    const apiKey = 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMyMzcwMjU1MiwiYWFpIjoxMSwidWlkIjoyNzIxMjM5OCwiaWFkIjoiMjAyNC0wMi0yMVQxMDozMzo1OC4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6ODcwMDcxMSwicmduIjoidXNlMSJ9.9yoit6unhjgd-EXOzNECPaCcYsjN34kDNs6gNNAexCk'; // Замените на ваш API-ключ

    try {
        const response = await axios.post(
            url,
            { query: query },
            {
                headers: {
                    Authorization: apiKey,
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log('Response data:', response.data);
    } catch (error) {
        console.error('Error creating item in Monday:', error);
    }
};

