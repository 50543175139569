import React, { useState, useEffect } from 'react';
import FeedbackFormComponent from '../forms/feedback.js'
import "../../styles/contacts.css";
import { ReactComponent as Tg } from '../../images/svg/tg.svg';
import { ReactComponent as Vk } from '../../images/svg/vk.svg';

const Marquee = ({ click, direction = "left" }) => {
    const components = ['Продвижеение', 'Аналитика', 'Оформление', 'Стратегия', 'Планирование', 'Реклама', 'Контент', 'SMM', 'SEO', 'PR', 'Копирайтинг'];
    const [classN, setClassN] = useState('marquee-content');

    useEffect(() => {
        if (direction !== 'left') {
            setClassN('marquee-content2');
        }
    }, [direction]);

    return (
        <div className="marquee">
            <div className={classN}>
                {components.map((component, index) => (
                    <div className="marquee-item" key={index}>
                        <div onClick={click} className='mi-slider-comp'> {component} </div>
                        <div className='mi-separator'></div>
                    </div>
                ))}
                {components.map((component, index) => (
                    <div className="marquee-item" key={index}>
                        <div onClick={click} className='mi-slider-comp'> {component} </div>
                        <div className='mi-separator'></div>
                    </div>
                ))}
                {/* ПРОДУБЛИРОВАТЬ! */}
            </div>
        </div>
    );
};

function Contacts({ openModal }) {

    const handleOpenModal = () => {
        openModal({ children: <FeedbackFormComponent /> });
    };

    return (
        <>
            <Marquee direction='left' click={handleOpenModal} />

            <div className='contacts'>

                <div className='c-left'>
                    <img className='ceo-photo' src='../../imgs/images/aleksandr.cc36f862.png' alt='' />
                </div>
                <div className='c-right'>
                    <div className='cr-pre-title'>ceo Gordeev media</div>
                    <div className='cr-title'>Александр<br />Гордеев</div>
                    <div className='cr-description'>
                        <p>Мы работаем на рынке уже <span>7-ой год. </span>За это время мы сделали <br /> огромное количество успешных проектов.</p>
                        <p>Мы стараемся сделать так, чтобы наши клиенты были довольны результатом!</p><br />
                        <p>Мои социальные сети:</p>
                    </div>
                    <div className='cr-social'>
                        <Tg onClick={() => { window.open('https://t.me/smmbig', '_blank') }} className='crs-soc-ico crs-tg' />
                        <Vk onClick={() => { window.open('https://vk.com/cbegachok', '_blank') }} className='crs-soc-ico crs-vk' />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contacts;
