import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import '../../styles/feedback.css'
import { createLeadInMonday } from '../../services/api.js'

const FeedbackFormComponent = ({ wishes = true, title = "Заполнение брифа", description = 'Заполни форму и стань звездой!' }) => {
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        try {
            if (!formData.phone || !formData.name) {
                throw new Error('Все поля обязательны для заполнения.');
            }
            console.log(formData.phone)
            var phoneFormatted = formData.phone.replace(' ', '').replace(' ', '').replace('(', '').replace(')', '').replace('+', '').replace('-', '').replace('-', '')
            console.log(phoneFormatted)
            
            createLeadInMonday(formData.name, phoneFormatted, formData.message ? formData.message : '')
            
            throw new Error('Форма успешна отправлена!');
            // console.log(formData);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className='feedback-form'>
            <h2 className='fbf-title'>{title}</h2>
            <h3 className='fbf-description'>{description}</h3>
            {error && <div className='fb-err-msg'>{error}</div>}
            <label className='fb-label'>Контакты</label>
            <div className='fb-part'>
                {/* Добавляем маску для ввода телефона */}
                <InputMask
                    mask="+7 (999) 999-99-99"
                    value={formData.phone || ''}
                    onChange={handleChange}
                >
                    {(inputProps) => (
                        <input
                            {...inputProps}
                            name="phone"
                            placeholder="Ваш телефон"
                            className='fb-inp'
                        />
                    )}
                </InputMask>
                <input
                    name="name"
                    placeholder="Ваше имя"
                    className='fb-inp'
                    onChange={handleChange}
                    value={formData.name || ''}
                />
            </div>
            {wishes ? <>
                <label className='fb-label'>Пожелания</label>
                <div className='fb-part'>
                    <input
                        name="message"
                        placeholder="Хочу по красоте!"
                        className='fb-inp'
                        onChange={handleChange}
                        value={formData.message || ''}
                    />
                </div>
            </> : ''}
            <div className='fb-btn'>
                <div className='btn' onClick={handleSubmit}>Отправить</div>
            </div>
        </div>
    );
};

export default FeedbackFormComponent;
