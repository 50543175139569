import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import '../../styles/consult_form.css';

const ConsultForm = () => {
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        try {
            if (!formData.phone || !formData.name || !formData.email) {
                throw new Error('Все поля обязательны для заполнения.');
            }
            setError(null);
            console.log(formData);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className='consult-form-block'>
            <label className='cfb-logo-label'>GORDEEV MEDIA</label>
            <div className='cfb-title'>НУЖНА<br />КОНСУЛЬТАЦИЯ?</div>
            {error && <div className='cfb-err-msg'>{error}</div>}
            <form className='cfb-form' onSubmit={handleSubmit}>
                <InputMask
                    mask="+7 (999) 999-99-99"
                    value={formData.phone || ''}
                    onChange={handleChange}
                >
                    {(inputProps) => (
                        <input
                            {...inputProps}
                            name="phone"
                            placeholder="Ваш телефон"
                            className='cfb-form-input'
                        />
                    )}
                </InputMask>
                <input
                    name="name"
                    placeholder="Ваше имя"
                    className='cfb-form-input'
                    onChange={handleChange}
                    value={formData.name || ''}
                />
                <input
                    name="email"
                    placeholder="Ваш E-MAIL"
                    className='cfb-form-input'
                    onChange={handleChange}
                    value={formData.email || ''}
                />
                <button className='cfb-form-submit' type='submit'>Получить консультацию</button>
            </form>
        </div>
    );
};

export default ConsultForm;
