import React from 'react';
import NavBtn from '../../components/buttons/navbtn.js';
import { useNavigate } from 'react-router-dom';
import FeedbackFormComponent from '../forms/feedback.js'
import '../../styles/header.css';


function Header({ refs, openModal }) {

    const navigate = useNavigate();

    const handleOpenModal = () => {
        openModal({ children: <FeedbackFormComponent wishes={false} title={'Обратная связь'} /> });
    };

    const menuLinks = {
        1: {
            title: "Главная",
            link: "#home"
        },
        2: {
            title: "Услуги",
            link: "#services"
        },
        3: {
            title: "Кейсы",
            link: "#cases"
        },
        4: {
            title: "Контакты",
            link: "#contacts"
        }
    };

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className='header'>
            <div className='header-logo'>
                <img onClick={() => (navigate('/'))} className='header-logo-img' src='/imgs/images/logo_ver1.png' alt='' />
            </div>
            <div className='header-navmenu'>
                {Object.values(menuLinks).map((item, index) => (
                    <NavBtn key={index} onClick={() => scrollToRef(refs[item.link.substring(1)])}>
                        {item.title}
                    </NavBtn>
                ))}
            </div>
            <div className='header-paybtn btn inactive-btn'>Оплатить</div>
            <div className='header-phone'>
                <img className="header-phone-cbc_icon" src='/imgs/images/phone.7f02738a.png' alt='' />
                <label onClick={() => { window.open('tel:+79671648925', '_blank') }} className='header-phone-cbc_phone'>+7 (967) 164-89-25</label>
            </div>
            <div onClick={() => { window.open('https://vk.com/cbegachok', '_blank') }} className='header-social'>
                <img className="header-phone-cbc_icon" src='/imgs/images/about_vk.1339e0b3.png' alt='' />
            </div>
            <div className='header-callback'>
                <div onClick={handleOpenModal} className='btn btn-white'>Перезвонить мне</div>
            </div>
        </div>
    );
}

export default Header;
