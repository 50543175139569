import React, { useState, useEffect } from 'react'
import "../../styles/cases.css"
import FeedbackFormComponent from '../forms/feedback.js'


const ImageSlider = ({ images }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="image-slider">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`image-container ${index === currentIndex ? 'active' : ''}`}
                    style={{ backgroundImage: `url(${image.src})` }}
                />
            ))}
        </div>
    );
};



function Cases({ openModal }) {

    const images = [
        { src: '../../imgs/artists/1 (4).png', alt: 'Image 1' },
        { src: '../../imgs/artists/2.png', alt: 'Image 2' },
        { src: '../../imgs/artists/3.png', alt: 'Image 3' },
        { src: '../../imgs/artists/4.png', alt: 'Image 4' },
        { src: '../../imgs/artists/6.png', alt: 'Image 6' },
        { src: '../../imgs/artists/7 (1).png', alt: 'Image 7' },
        { src: '../../imgs/artists/8.png', alt: 'Image 8' },
        { src: '../../imgs/artists/9.png', alt: 'Image 9' },
        { src: '../../imgs/artists/10.png', alt: 'Image 10' },
        { src: '../../imgs/artists/11.png', alt: 'Image 11' },
        { src: '../../imgs/artists/12.png', alt: 'Image 12' },
        { src: '../../imgs/artists/13.png', alt: 'Image 13' },
        { src: '../../imgs/artists/14.png', alt: 'Image 14' },
        { src: '../../imgs/artists/15.png', alt: 'Image 15' },
        { src: '../../imgs/artists/16.png', alt: 'Image 16' },
        { src: '../../imgs/artists/17.png', alt: 'Image 17' },
        { src: '../../imgs/artists/18.png', alt: 'Image 18' },
        { src: '../../imgs/artists/19.png', alt: 'Image 19' },
        { src: '../../imgs/artists/20.png', alt: 'Image 20' },
        { src: '../../imgs/artists/21.png', alt: 'Image 21' }
    ];

    const handleOpenModal = () => {
        openModal({ children: <FeedbackFormComponent /> });
    };

    return (
        <div className='cases'>
            <div className='cases-left-side'>
                <div className='cases-label'>
                    <div className='cl-first'>НАШИ</div>
                    <div className='cl-sec'>КЕЙСЫ</div>
                    <div className='cl-logo-label'>GORDEEV MEDIA</div>
                </div>
                <div className='cases-description'>
                    <div className='cd-text'>Мы реализовали</div>
                    <div className='cd-text cd-text-custom'>более 500</div>
                    <div className='cd-text'>успешных проектов</div>
                </div>
                <div className='cases-addictional'>
                    <div onClick={handleOpenModal} className='btn btn-ca'>Заполнить бриф</div>
                    <div className='ca-description'>Продвинем Вашу музыку<br />в топы музыкальных чартов!</div>
                </div>
            </div>
            <div className='cases-right-side'>
                <ImageSlider images={images} />
            </div>
        </div>
    )
}

export default Cases