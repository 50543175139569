import React from 'react';

function NavBtn({ onClick, children }) {
    return (
        <button onClick={onClick} className='nav-btn'>
            {children}
        </button>
    );
}

export default NavBtn;
