import React, { useState } from 'react'
import arrow from '../../images/services_block/arrow_right.464f7ba5.png'
import whiteArrow from '../../images/services_block/arrow_right_white.07e2a333.png'
import FeedbackFormComponent from '../forms/feedback.js'

import "../../styles/services.css"

function CardElement({ title, icon, description, steps, msg = "", big = false, btn }) {

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(!isClicked);
    }



    const formattedDescription = description.split('\n');

    if (big) {

        return (
            <div className='card-element-big'>
                <div className='card-element-left-side'>
                    {msg ? <div className='ce-msg'>{msg}</div> : ''}
                    <div className='ce-dotted'>. . .</div>
                    <div className='ce-title'>{title}</div>
                    <div className='ce-description'>{
                        formattedDescription.map((item, index) =>
                            <p key={index} >{item}</p>
                        )}
                    </div>
                    <div onClick={btn} className='ce-btn'>
                        <p className='ce-btn-txt'>Мне это нужно</p>
                        <img className='ce-btn-img' src={whiteArrow} alt='' />
                    </div>
                </div>
                <img className='ce-img' src={icon} alt='' />
            </div>
        )
    } else if (steps) {

        return (
            !isClicked ?
                <div className='card-element' onClick={handleClick}>
                    {msg ? <div className='ce-msg'>{msg}</div> : ''}
                    <img className='ce-img' src={icon} alt='' />
                    <div className='ce-dotted'>. . .</div>
                    <div className='ce-title-arrow'>
                        <div className='ce-title'>{title}</div>
                        <img className='ce-arrow' src={arrow} alt='' />
                    </div>
                    <div className='ce-description'>{
                        formattedDescription.map((item, index) =>
                            <p key={index} >{item}</p>
                        )}
                    </div>
                </div>
                :
                <div className='card-element card-element-alt' onClick={handleClick}>
                    {msg ? <div className='ce-msg ce-msg-right'>{msg}</div> : ''}
                    <div className='ce-title-arrow ce-title-arrow-alt'>
                        <div className='ce-title'>{title}</div>
                    </div>
                    <div className='ce-description-steps'>
                        {Object.values(steps).map((value, index) => {
                            return (
                                <div key={index} id={`cds-line-${index}`} className='cds-line'>
                                    <div className='cds-text'>{value}</div>
                                    <div className='cds-number'>{index + 1}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div onClick={btn} className='ce-btn ce-btn-alt'>
                        <p className='ce-btn-txt ce-btn-txt-alt'>Мне это нужно</p>
                        <img className='ce-btn-img' src={whiteArrow} alt='' />
                    </div>
                </div>
        )
    } else { }
}

function Services({ openModal }) {

    const handleOpenModal = () => {
        openModal({ children: <FeedbackFormComponent /> });
    };

    const servicesList = {
        1: {
            title: "Комплексное продвижение",
            icon: '/imgs/images/megaphone.62ecbacc.png',
            msg: 'Популярно',
            description: 'Аналитика прошлых рекламных кампаний\nРазработка индивидуальной стратегии',
            steps: {
                1: "Аналитика прошлых рекламных кампаний",
                2: "Подготовка к релизу",
                3: "Разработка индивидуальной стратегии",
                4: "Прогрев релиза",
                5: "Пост Промо релиза"
            }
        },
        2: {
            title: "Таргетированная реклама",
            msg: 'Популярно',
            icon: '/imgs/images/target.d343b670.png',
            description: 'Аналитика прошлых рекламных кампаний\nПодбор социальных сетей для рекламы',
            steps: {
                1: "Аналитика прошлых рекламных кампаний",
                2: "Подбор социальных сетей для рекламы",
                3: "Настройка таргетированной рекламы",
            }
        },
        3: {
            title: "Контент - менеджмент",
            icon: '/imgs/images/content.f9a82c06.png',
            description: 'Подбор социальных сетей для рекламы\nздание контент-плана',
            steps: {
                1: "Подбор социальных сетей для рекламы",
                2: "Техническая настройка социальных сетей",
                3: "Визуальное оформление социальных сетей",
                4: "Создание контент-плана",
                5: "Ведение социальных сетей",
            }
        },
        4: {
            title: "Продвижение бизнеса",
            msg: 'Популярно',
            big: true,
            icon: '/imgs/images/rocket.74ea9153.png',
            description: '- Аналитика прошлых рекламных кампаний\n- Подбор социальных сетей для рекламы\n- Настройка таргетированной рекламы\n- Ведение сообщества бизнеса в социальных сетях',
        },
        5: {
            title: "Музыкальная аналитика",
            icon: '/imgs/images/video_player.2b5d41fc.png',
            description: 'Стриминговая аналитика артиста\nАналитика музыкантов разных лейблов',
            steps: {
                1: "Аналитика прошлых рекламных кампаний",
                2: "Стриминговая аналитика артиста",
                3: "Поиск восходящих звезд на разных платформах",
                4: "Аналитика музыкантов разных лейблов",
            }
        },
        6: {
            title: "Дистрибьюция музыкального контента",
            icon: '/imgs/images/stats.3c3ec361.png',
            description: 'Помощь в дистрибьюции артиста\nПодготовка материалов для отправки в лейбл',
            steps: {
                1: "Поиск лейблов для артиста",
                2: "Помощь в дистрибьюции артиста",
                3: "Подготовка материалов для отправки в лейбл",
                4: "Помощь в позиционировании артистов перед лейблами",
            }
        },
        7: {
            title: "Продвижение концертов и мероприятий",
            icon: '/imgs/images/concert.10b332c5.png',
            description: 'Помощь в дистрибьюции артиста\nПодготовка материалов для отправки в лейбл',
            steps: {
                1: "Аналитика прошлых рекламных кампаний",
                2: "Подбор социальных сетей для рекламы",
                3: "Настройка таргетированной рекламы",
                4: "Ведение сообществ мероприятий в социальных сетях",
            }
        },
    }

    return (
        <div className='services-block'>
            <div className='sb-title'>Услуги</div>
            <div className='sb-card-list'>
                {Object.values(servicesList).map((service, index) => (
                    <CardElement
                        key={index}
                        title={service.title}
                        icon={service.icon}
                        description={service.description}
                        steps={service.steps}
                        msg={service.msg}
                        big={service.big}
                        btn={handleOpenModal}
                    />
                ))}
            </div>
        </div>
    )
}

export default Services