import React from 'react';


function Guides() {
    return (
        <div>Guides</div>
    );
}

export default Guides;
