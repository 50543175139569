import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/footer/footer';

import Home from './routes/Home';
import Guides from './routes/Guides';

import './styles/main.css';
import './styles/mobile.css'

function App() {


  useEffect(() => {
    let viewWidth,
      viewHeight,
      canvas = document.getElementById('canvas'),
      ctx;

    // Настройки
    const patternSize = 64,
      patternScaleX = 2,
      patternScaleY = 2,
      patternRefreshInterval = 1,
      patternAlpha = 10;

    const patternPixelDataLength = patternSize * patternSize * 4;
    let patternCanvas,
      patternCtx,
      patternData,
      frame = 0;

    // Инициализация canvas
    function initCanvas() {
      viewWidth = canvas.width = canvas.clientWidth;
      viewHeight = canvas.height = canvas.clientHeight;
      ctx = canvas.getContext('2d');

      ctx.scale(patternScaleX, patternScaleY);
    }

    // Инициализация зернистости
    function initGrain() {
      patternCanvas = document.createElement('canvas');
      patternCanvas.width = patternSize;
      patternCanvas.height = patternSize;
      patternCtx = patternCanvas.getContext('2d');
      patternData = patternCtx.createImageData(patternSize, patternSize);
    }

    // Обновление зернистости
    function update() {
      let value;

      for (let i = 0; i < patternPixelDataLength; i += 4) {
        value = (Math.random() * 255) | 0;

        patternData.data[i] = value;
        patternData.data[i + 1] = value;
        patternData.data[i + 2] = value;
        patternData.data[i + 3] = patternAlpha;
      }

      patternCtx.putImageData(patternData, 0, 0);
    }

    // Отрисовка зернистости
    function draw() {
      ctx.clearRect(0, 0, viewWidth, viewHeight);
      ctx.fillStyle = ctx.createPattern(patternCanvas, 'repeat');
      ctx.fillRect(0, 0, viewWidth, viewHeight);
    }

    // Анимационный цикл
    function loop() {
      if (++frame % patternRefreshInterval === 0) {
        update();
        draw();
      }

      requestAnimationFrame(loop);
    }

    // Запуск анимации после загрузки
    initCanvas();
    initGrain();
    requestAnimationFrame(loop);

    // Очистка при размонтировании компонента
    return () => {
      cancelAnimationFrame(loop);
    };
  }, []);


  return (
    <Router>
      <canvas id="canvas" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: -1
      }}></canvas>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/guides" element={<Guides />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
